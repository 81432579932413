<template>
  <IAmOverlay :loading="loading">
    <b-card>
      <form-wizard
        ref="refFormWizard"
        :title="null"
        :subtitle="null"
        hide-buttons
        class="custom-form-wizard steps-transparent"
      >
        <tab-content
          :title="$t('employee.tabInfo')"
          icon="feather icon-user"
        >
          <employees-add-tab-account
            :employee-data.sync="employeeData"
            @next-step="formWizardNextStep"
          />
        </tab-content>

        <tab-content
          :title="$t('employee.confirmation')"
          icon="feather icon-check"
        >
          <employees-add-confirm
            :employee-data.sync="employeeData"
            @prev-step="formWizardPrevStep"
            @submit="submitHandle"
          />
        </tab-content>
      </form-wizard>
    </b-card>
  </IAmOverlay>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onUnmounted, ref } from '@vue/composition-api'

import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'
import { getUserData } from '@/api/auth/utils'
import { useRouter } from '@/@core/utils/utils'

import useEmployeeHandle from '@employee/useEmployeeHandle'
import employeeStoreModule from '@employee/employeeStoreModule'

import EmployeesAddConfirm from './EmployeesAddConfirm.vue'
import EmployeesAddTabAccount from './EmployeesAddTabAccount.vue'

export default {
  components: {
    BCard,
    FormWizard,
    TabContent,
    IAmOverlay,

    // components
    EmployeesAddTabAccount,
    EmployeesAddConfirm,
  },
  setup() {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    const employeeData = ref({
      // require
      firstName: '',
      lastName: '',
      type: '',
      username: '',
      emailAddress: '',
      creator: getUserData().employeeData.id,
      // optional
      // avatar:  '',
      gender: 'MALE', // default
    })

    const { router } = useRouter()
    const { addEmployee } = useEmployeeHandle()

    const loading = ref(false)
    const submitHandle = () => {
      if (employeeData.value.type !== 'BE') {
        employeeData.value.bookerType = null
      }

      loading.value = true
      addEmployee(employeeData.value)
        .then(() => {
          router.push({ name: 'apps-employees-list' })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,

      employeeData,
      submitHandle,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.custom-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
