<template>
  <section>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="employeeData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('employee.errFetchEmpData') }}

      </h4>
      <div class="alert-body">
        {{ $t('employee.gotoEmp_1') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-employees-list'}"
        >
          {{ $t('employee.gotoEmp_2') }}
        </b-link>
      </div>
    </b-alert>

    <b-card
      v-if="employeeData"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border mt-1"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('previewMail') }}
        </h6>
      </template>

      <b-card-body
        class="p-0 pt-1"
      >
        <h3> {{ $t('createdMsg_1') }}</h3>

        <b-card-text class="py-2">
          {{ $t('sendTo') }} <span class="font-weight-bold ">{{ employeeData.emailAddress }}</span>
        </b-card-text>

        <b-card-text class="font-weight-bold">
          {{ $t('createdMsg_2') }}
        </b-card-text>

        <b-list-group class="mb-2">
          <b-list-group-item>
            <b-row>
              <b-col
                cols="3"
              >
                {{ $t('email') }}
              </b-col>
              <b-col>
                {{ employeeData.emailAddress }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row>
              <b-col
                cols="3"
              >
                {{ $t('password') }}
              </b-col>
              <b-col>
                <span class="text-muted">{{ $t('passwordMuted') }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>

        <b-card-text class="font-weight-bold mt-3">
          {{ $t('createdMsg_3') }}
        </b-card-text>
      </b-card-body>
    </b-card>

    <div class="d-flex justify-content-center">

      <!-- Back button -->
      <b-button
        variant="secondary"
        class="mb-0 mr-50"
        @click="$emit('prev-step')"
      >
        {{ $t('back') }}
      </b-button>

      <!-- Next button -->
      <b-button
        variant="warning"
        class="mb-0 ml-50"
        :disabled="!canAccess('employee.createEmployee')"
        @click="sendHandle"
      >
        {{ $t('send') }}
      </b-button>
    </div>

  </section>
</template>

<script>
import {
  BButton, BRow, BCol,
  BCard,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem, BLink, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BLink,
    BAlert,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    function sendHandle() {
      emit('submit')
      // this.$router.push({ name: 'apps-employees-list' })
    }

    return {
      sendHandle,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
